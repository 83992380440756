import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormInput } from './DatePicker';
import InputField from "@kiwicom/orbit-components/lib/InputField";
import { FormatDateShort, headerRowStyle } from './Header';
import moment from 'moment'

const PlacesDataList = ( {id, search_term} ) => {

    const [areaOptions, setAreaOptions] = useState({'NYC': 'New York'});
    useEffect(() => {
        // only make call to backend when first letter is typed
        //if (search_term.length != 1) return

        const url = `/v1/querylocationscodes?&search_term=${search_term}`
        fetch(url)
        .then((response) => {
            return response.json()
              .then((json) => {
                if (response.ok) {
                  return Promise.resolve(json)
                }
                return Promise.reject(json)
              })
          })
        .then(setAreaOptions)
        .catch((ex) => {
            console.log(`Exception fetching: ${url}`, ex)
            // setAreaOptions([]) // clear areaOptions?
        });
    }, [search_term]);

    return (
        <datalist id={id}>
        {
          areaOptions && Object.keys(areaOptions).map( (item, index) =>
              <option value={`${item} (${areaOptions[item]})`} key={index}>{areaOptions[item]}</option>
          )
        }
        </datalist>
    )

}

export const TogglerInput = ( { options = ["→", "⇄"], addlStyle = {flex: '0 0 16px', margin: '2px'} } ) => {
    const [isChecked, setIsChecked] = useState(false);
    const toggleChecked = () => setIsChecked(!isChecked)

    return (
      <label style={{WebkitUserSelect: 'none', ...addlStyle}}>
        <input disabled type="checkbox" onChange={toggleChecked} style={{display: 'none'}}/>
        {options[isChecked ? 1 : 0]}
      </label>
    );
}

const DateInput = ( props ) => {
  //console.log(props)
  const {label, register, ...inputProps} = props;
  return (
  <input 
    type="date" 
    className="styledDate"
    {...inputProps}
    {...(register && register(props.name))}
    data-display-value={props.defaultValue ? FormatDateShort(props.defaultValue) : label}
    onChange={(e) => {
      const formattedDate = FormatDateShort(e.target.value)
      e.target.dataset.displayValue = formattedDate == 'Invalid date' ? label : formattedDate
    }}
    //onFocus={(e) => { e.target.type = 'date'; }} //integrate ref /hook-form?
    //placeholder={label}  // type=date does not show placeholder
  />
  );
}

export const FlightSearchInputs = ( {register, watch, details} ) => {
  const locationWatcher = watch('cityCodeTo') //register as actual input instead of textField?
  return (
  <>
  <div className="header-container" style={{...headerRowStyle}}>
    <div style={{display: 'inline-flex',flex: 'auto',alignItems: 'center',width: '40%'}}>
      <input type="text" id="cityCodeFrom" name="cityCodeFrom" placeholder="From" defaultValue={details?.cityCodeFrom}
        {...register('cityCodeFrom')}
        style={{flex: '0 0 40%'}}/>
      <TogglerInput addlStyle={{flex: '0 0 32px', margin: '2px', fontSize: '23pt'}}/>
      { locationWatcher && <PlacesDataList id="areaOptions" search_term={locationWatcher[0]}/> }
      <input type="text" id="cityCodeTo" name="cityCodeTo" placeholder="To" defaultValue={details?.cityCodeTo}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
        list="areaOptions"
        //maxLength="10"
        /*onChange={(e) => {
          e.target.value = e.target.value.slice(0,3)
          //e.target.dataset.displayValue = FormatDateShort(e.target.value)
        }}*/
        //inputmode={'none'}
        {...register('cityCodeTo')}
        style={{flex: '0 0 40%'}}/>
    </div>

    <div style={{display: 'inline-flex',flex: 'auto', width: '25%'}}>
    <DateInput 
      style={{width: '67%', marginLeft: 'auto'}}
      register={register}
      id="start_date"
      name="start_date"
      label="When?"
      //min={moment().format('YYYY-MM-DD')} // doesnt work on iOS
      defaultValue={details?.start_date
                    ? moment(details.start_date, "YYYY-MM-DD hh:mm").format('YYYY-MM-DD')
                    : '' /*moment().format('YYYY-MM-DD')*/}
    />
    </div>
  </div>

  <div className="header-container" style={{...headerRowStyle}}>
    <div style={{columnGap: '4px', display: 'inline-flex',flex: 'auto',alignItems: 'center',width: '40%'}}>
      {/* <label style={{WebkitUserSelect: 'none', verticalAlign: 'middle'}}>
        Direct?<input type="checkbox" switch="true"/>
      </label> */}
      <select name="num_passengers" style={{flex: '0 0 40%'}} defaultValue={details?.num_passengers} {...register('num_passengers')}>
        <option value="1">1 guest</option>
        <option value="2">2 guests</option>
        <option value="3">3 guests</option>
        <option value="4">4 guests</option>
      </select>
      <select name="selected_cabins" style={{flex: '0 0 40%'}} defaultValue={details?.selected_cabins} {...register('selected_cabins')}>
        <option value="M">Economy</option>
        <option value="W">Premium</option>
        <option value="C">Business</option>
        <option value="F">First class</option>
      </select>
      <select name="max_stops" style={{flex: '0 0 40%'}} defaultValue={details?.max_stops} {...register('max_stops')}>
        <option value="0">Direct</option>
        <option value="1">1 stop</option>
        <option value="2">2 stops</option>
        <option value="20">∞ stops</option>
      </select>
    </div>

    <div style={{display: 'inline-flex',flex: 'auto', width: '25%'}}>
    <DateInput 
      style={{width: '67%', marginLeft: 'auto'}}
      register={register}
      id="end_date"
      name="end_date"
      label="Return?"
      defaultValue={details?.end_date
                    ? moment(details.end_date, "YYYY-MM-DD hh:mm").format('YYYY-MM-DD')
                    : '' /*moment().format('YYYY-MM-DD')*/}
    />
    </div>
  </div>
  </>
  );
}

export const FlightSearchAdder = ( {register, watch, details} ) => {
  const locationWatcher = watch('cityCodeTo')

  /*flex-grow: 2; flex-shrink: 2; flex-basis: 6em;*/

  return (
    <>
        {locationWatcher && <PlacesDataList id="areaOptions" search_term={locationWatcher[0]}/>}
        {/*<PlacesDataList id="areaOptions" search_term={locationWatcher?.[0] || 'NYC'}/>*/}
        
        <div style={{display: 'inline-flex',flex: 'auto',alignItems: 'center'}}>
        <InputField 
          label="Origin"
          placeholder="From"
          value={details?.cityCodeFrom}
          // list="areaOptions" // should be supported but not working
          name="origin"
          {...register('cityCodeFrom')}
        />
        <TogglerInput addlStyle={{fontSize: '23pt', color: 'white', margin: '20px 12px 0px'}}/>
        <FormInput 
          type="text"
          id="destination"
          placeholder="To"
          defaultValue={details?.cityCodeTo}

          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          list="areaOptions"
          label="Where to?"
          register={register}
          name="cityCodeTo"
        />
        </div>

        <FormInput 
          type="date"
          id="start_date"
          defaultValue={details?.start_date || moment().add(1, 'months').format('YYYY-MM-DD')}
              
          label="Takeoff Date"
          register={register}
          name="start_date"
        />
    </>
  );
};

export const FlightSearchForm = ( {submitHandler, details} ) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, watch, handleSubmit, setValue } = useForm();

    const submitPayload = (data) => {
      const {link, name, ...planDetails} = data;
      setIsLoading(true)
      submitHandler(planDetails)
      setIsLoading(false) // basically instant, maybe remove both setIsLoading?
    }
  
    return (
      <form 
        noValidate 
        onSubmit={handleSubmit(submitPayload)}
        className="header-container active" 
        {...(!submitHandler ? {inert: 'true', className: "header-container inert" } : {})}
      >
        <FlightSearchInputs details={details} register={register}  watch={watch} setValue={setValue}/>
        <div style={{ ...headerRowStyle, justifyContent: 'center' }}>
        <button style={{ margin: '2px 0px', width: '64px', height: '24px' }} type="submit">Search</button>
        </div>
      </form>
    );
};

  /*  
  Problem: keyboard datalist does not take values on ios 17.6

  Options:
  1. Current: show suggest, but user must type full code (or click arrow and select)
  2. Pair a text or search input w/ a dynamically updating select (optgroups?) 
  -showPicker() not supported on iOS but focus() works with a setTimeout


  1. add datalist suggestions to all places (even tho click doesnt work in ios 17)
  2. add ios style price/buy button
  ---demo?
  3. add heart button / save into query string / move to top of results
  4. consider named flights (e.g. Graham's)
  5. consider using ionic typeahead or write custom using native selects + timed .focus()
  
  after/before work time toggle?

  HeaderSmall should really just be a Header w/ 1 line carved out at top
  -and subheader (hidden in stack) part of HeaderSmall?
  -1 form wrapping entire thing?
  -form should be switchable depending on planType
  */