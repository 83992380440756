import { useEffect } from "react";
import InputField from "@kiwicom/orbit-components/lib/InputField";

export const ListAdder = ( {register, watch, setValue} ) => {
  const linkWatcher = watch('link', '')
  
  const getSheetIdFromLink = (link) => {
    const pattern = /\/d\/([^\/]+)/
    const captured = link.match(pattern)

    return captured[1]
  }
  
  register("sheetId");
  
  useEffect(() => {
    if (linkWatcher.length > 0) setValue("sheetId", getSheetIdFromLink(linkWatcher));
  }, [linkWatcher]);

  return (
    <>
      <InputField 
        label="Paste a link to the plan" 
        placeholder="link"
        name="link"
        {...register('link')}
        //prefix={<Icons.Link />}
      />
      <InputField 
        label="Give it a name" 
        placeholder="name" 
        name="name"
        {...register('name')}
      />
      <InputField    //DatePicker
        label="And a date/range (July 4, June 2023, etc.)" 
        placeholder="meta text"
        name="meta"
        {...register('meta')}
      />
      <InputField
        label="And a picture (optional)" 
        placeholder="image link" 
        name="img"
        {...register('img')}
      />
    </>
  );
};