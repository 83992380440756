import { useState } from "react";

export const PlusButton = ( {diameter, status, onClick} ) => {
  const imgURL = process.env.PUBLIC_URL + '/plus.svg'
  
  var buttonStyles = {
    width: diameter,
    height: diameter,
    borderRadius: '100%',
    backgroundColor: '#fff',
    backgroundImage: `url(${imgURL})`,
    backgroundPosition: '50% 50%',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat'
  }

  return (
    <div style={buttonStyles} className={status} onClick={onClick}></div>
  );
}