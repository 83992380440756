export const ListButton = ( {diameter, selOptions, changeHandler} ) => {
  const imgURL = process.env.PUBLIC_URL + '/list.svg'
  
  const buttonStyles = {
    width: diameter,
    height: diameter,
    borderRadius: '100%',
    backgroundColor: '#fff',
    backgroundImage: `url(${imgURL})`,
    backgroundPosition: '50% 50%',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat'
  }

  const selectStyles = {
    width: diameter,
    height: diameter,
    position: 'relative',
    top: '-10px',
    right: '0px',
    outline: 'none',
    border: 'transparent',
    color: 'transparent',
    backgroundColor: 'transparent'
  }

  let optionIndex = 0;

  return (
    <div style={buttonStyles}>
    <select
      style={selectStyles} 
      name="options" 
      onChange={(event) => changeHandler(event.currentTarget.value)}
    >
      {selOptions.map((option, index) => {
        if (typeof option === 'object') {
          // option groups are represented by an object w/ one key for the group's label
          const groupKey = Object.keys(option)[0];
          const groupOptions = option[groupKey];
          return (
            <optgroup key={index} label={groupKey}>
              {groupOptions.map((item) => {
                const optionKey = optionIndex++
                return (
                 <option key={optionKey} value={item}>
                    {item}
                 </option>
                );
              })}
            </optgroup>
          );
        } else {
          const optionKey = optionIndex++
          return (
            <option key={optionKey} value={option}>
              {option}
            </option>
          );
        }
      })}
    </select>
    </div>
  );
}