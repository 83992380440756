import react, { useEffect, useState } from "react";
import { PlusButton } from './components/PlusButton';
import { ListButton } from './components/ListButton';
import {SegmentAdder} from './SegmentAdder';
import {PlanCard} from './PlanCard';
import {BlurBar} from './components/BlurBar';
import Card from './cardStack/Card';
import CardStack from './cardStack/CardStack';

export const PWA = ({ stack = false }) => {
  const [showPlusModal, setShowPlusModal] = useState(false);
  const [status, setStatus] = useState('');
  const [plans, setPlans] = useState([]);
  const [selValue, setSelValue] = useState('all');
  const [selOptions, setSelOptions] = useState([]);

  const setData = (data) => {
    setPlans(data)

    const options = !stack
         ? data.map(p => p.planName)
         : ['all', { 'plan types': Array.from(new Set(data.map(p => p.planType))) }]
    setSelOptions(options)
    !stack && setSelValue(options[0])
  }

  const plansURL = '/plans' //'/testplans' //

  useEffect(()=>{
     setStatus('loading');
     fetch(plansURL)
       .then(response => response.json())
       .then(resjson => resjson.plans.length > 0 && setData(resjson.plans.toReversed()))
       .then(()=>setStatus('success'))
       .catch(()=>setStatus('error'));
     }, []);

  const addTrip = (payload) => {
    fetch(plansURL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(resjson => setData(resjson.plans.toReversed()))
    .then(()=>setStatus('success'))
    .then(()=>setShowPlusModal(false)) //block/show loading until plan is added
    .catch(()=>setStatus('error'));
  }

  const toolbarStyle = {
    overscrollBehavior: 'contain', //https://dev.to/mpuckett/the-holy-grail-web-app-shell-with-header-and-footer-for-iphone-549j
    //overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#fff', 
    padding: '2.25vh 0vw',//'4.5vw 0vw', //slightly less padding than native passbook
    fontSize: '4.5vh', 
    maxHeight: '4.5vh',
    fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif',
    //https://stackoverflow.com/questions/32660748/how-to-use-apples-new-san-francisco-font-on-a-webpage
  }

  const plansFiltered = plans.filter((p) => selValue == 'all' || selValue == p.planType || selValue == p.planName)

  // bodyHeight = totalHeight - toolbarHeight - blurbarHeight (if it's visible)
  const totalHeightInPixels = window.innerHeight;
  const toolbarHeight = totalHeightInPixels * (.045 + .045)
  const blurbarHeight = totalHeightInPixels * .045 * (window.navigator.standalone | 0)
  const bodyHeightInPixels = Math.floor(totalHeightInPixels - (toolbarHeight + blurbarHeight));

  return (
    <div style={{padding: '0vw 4.5vw'}}>
       <div style={toolbarStyle}>
        {status !== 'error'
                ? <b>Plans</b>
                : <b>No Plans Found</b>
        }
        <div style={{display: 'flex'}}>
          <ListButton diameter='4.5vh' selOptions={selOptions} changeHandler={(val) => setSelValue(val)}/>
          <div style={{width: '2.25vh'}}></div>
          <PlusButton diameter='4.5vh' status={status} onClick={() => setShowPlusModal(true)}/>
        {showPlusModal && <SegmentAdder submitHandler={addTrip} setShowPlusModal={setShowPlusModal}/>}
       </div>
       </div>

       {
        status === 'success' && plansFiltered.length === 0 && (
           <div style={toolbarStyle}>
             Hit the plus button to add a plan!
           </div>
        )
       }
       {
        status === 'success' && plans.length > 0 && (
           (!stack || plansFiltered.length === 1)
             ? <PlanCard trip={plansFiltered[0]} idx={0} />
             : <CardStack background='transparent' height={bodyHeightInPixels} footerHeight={blurbarHeight}>
                 {plansFiltered.map((plan, index) => (
                   <Card key={index}>
                     <PlanCard trip={plan} height={bodyHeightInPixels}/>
                   </Card>
                 ))}
               </CardStack>
        )
       }

       {window.navigator.standalone && <BlurBar/>}
     </div>
     );
}