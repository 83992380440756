import React from 'react';
import ReactDOM from 'react-dom'
import dragula from 'react-dragula'
import Itinerary from "@kiwicom/orbit-components/lib/Itinerary";
import * as Segments from './Segments'

function pojoSwitch(item) {
  const Segment = Segments.segmentTypes[item.type];
  return <Segment item={item}/>;
}

const styles = {
  //background: 'white', //remove for card look
  minHeight: '280px',
  width: '100%', // otherwise width is based on biggest inner element (nested link!)
  //margin: '1px auto',//overflow on right for PR... auto bad?
  overflowY: 'auto',
  overflowX: 'hidden',
  lineHeight: '30px'
}

class ItineraryList extends React.Component {

  constructor (props) {
       super(props);
       this.state = {item: ''};
       this.onInput = this.onInput.bind(this);
       this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount(){
    //var container = ReactDOM.findDOMNode(this);
    //dragula([container]);
  }

  onInput(event){
    this.props.toggleTodo(event.target)
  }

  onRemove(event){
    this.props.removeTodo(event.target);
  }

  render () {
      return (
        <div style={styles}>

        <Itinerary>
          {
            this.props.items && this.props.items.map( (item, index) =>

              <div key={index}>
              {pojoSwitch(item)}
              </div>
              )
          }
        </Itinerary>

        </div>
      );
  }
}

export default ItineraryList;