import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {Card} from './PlanCard';

export const SheetExpenses = () => {
  const {sheetId} = useParams();
  //const idx = parseInt(sheetId) //bring back for server-side IDs
  const url = `/sheetsexpenses?sheet_id=${sheetId}` //move endpoints to central file
  
  const [status, setStatus] = useState('');
  const [trips, setTrips] = useState([]);
  useEffect(()=>{
     setStatus('Loading');
     fetch(url)
       .then(response => response.json())
       .then(resjson => setTrips(resjson.trip))
       .then(()=>setStatus('Success'))
       .catch(()=>setStatus('Error'));
   }, []);

  const cardStyle = {
    background: 'white',
    height: '100svh',
  }

   return (
      <div>
         {status === 'Loading' && <div>Loading...</div>}
         {status === 'Error' && <div>There was an error</div>}
         {status === 'Success' && <Card trip={trips} cardStyle={cardStyle} />}
       </div>
     );
}