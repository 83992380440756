import React from 'react';

class Card extends React.Component {
    constructor (props) {
        super(props);
    }

    handleClick (event) {
        if (event.target.matches('input,label,select,button')) {
            return;
        }
        const {cardId, cardClicked} = this.props;
        this.props.onClick(cardId, cardClicked, event);
    }

    render () {
        const { cardId, cardSelected, height, topOffset, headerHeight } = this.props;

        const hovOffset = 0 //(cardId !== 0) && this.state.hover && cardId != cardSelected ? hoverOffset : 0;
        var translateToOffset = `translate3d(0,${topOffset - hovOffset}px,0)`;
        const cardStyles = {
            position: 'sticky',
            top: 0,
            width: '100%',
            background: this.props.background,
            borderTop: `1px solid color-mix(in srgb, ${this.props.background} 75%, black)`,
            WebkitTransform: translateToOffset,
            overflow: cardId < cardSelected ? 'clip' : 'visible',
            overflowClipMargin: '8px', // not supported on safari after all...
            //container: 'card-container / size',
            maxHeight: headerHeight,
            minHeight: headerHeight,
            transition: '0.5s transform ease',
        };

        return (
            <li
                style={cardStyles}
                onClick={this.handleClick.bind(this)}
            >
                {this.props.children}
            </li>
        );
    }
}

export default Card;