import React from 'react';

// just like an input element, except w/ additional label prop
export const FormInput = ( props ) => {
  const {label, register, ...inputProps} = props;

  return (
    <label className="InputField__Field-sc-19cgljf-0 jzgoBR">
    <span className="FormLabel-sc-14kkg44-2 kVDPSv">
      <span>{label}</span>
    </span>
    <div className="InputField__InputContainer-sc-19cgljf-2 gjMAnv">
      <input 
        {...inputProps}
        {...(register && register(props.name))}
        className="InputField__Input-sc-19cgljf-6 ihLOjl"
      />
      <div className="InputField__FakeInput-sc-19cgljf-1 isLhOw"></div>
    </div>
  </label>
  );
};

const datesStyle = {
  height: '44px',
  color: '#252A31',
  fontSize: '14px'
}

export const DatePicker = ( {register} ) => (
  <FormInput 
        type="datetime-local"
        id="startTime"
        style={datesStyle}
        register={register}
        label="Select a date and time"
  />
);
