import { StaticHeader } from './Header';
import { Divider } from './Divider';
import ItineraryList from './ItineraryList';
import { ResBody } from './Segments';
import { SearchBody } from './Segments';
import { FlightSearchForm } from './FlightSearchAdder';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '280px',
  maxHeight: '100svh',
}

//note: style props are upserted
export const Card = ( {trip, cardStyle, dividerStyle, planUpdater} ) => {
  
  const headers = {
    'input': <FlightSearchForm submitHandler={planUpdater} details={trip.planDetails}/>,
    //'search': <FlightSearchForm submitHandler={planUpdater} details={trip.planDetails}/>, //res
  }

  const segmentBodys = {
    'input': <SearchBody item={trip.planDetails}/>,
    'search': <SearchBody item={trip.planDetails}/>,
    'res': <ResBody item={trip.planDetails}/>
  }

  return (
      <div style={{...baseStyle, ...cardStyle}}>  
        <div style={{padding: '12px 12px 0px'}}>
          {headers[trip.planType] || <StaticHeader trip={trip}/>}
        </div>
        <Divider marginStyle={dividerStyle}/>
        <div onClick={(e) => e.stopPropagation()} style={{overflow: 'scroll'}}>
          {segmentBodys[trip.planType] || <ItineraryList items={trip.segments}/>}
        </div>
      </div>
  );
}

export const PlanCard = ( {trip, height, planUpdater} ) => {
  //const colors = ['#c12014','#f16d5e','#4bb491','#1482d2','#b2b3b2','#27AE60','#649ae1','#f0a164','#d2d2d2']
  const colors = ['#b2b3b2','#ffffff','#d2d2d2']
  const backgroundColor = colors[(trip.planId) % colors.length] || 'white'

  const cardStyle = {
    background: backgroundColor,
    border: `2px solid color-mix(in srgb, ${backgroundColor} 75%, black)`,
    boxSizing: 'border-box',
    boxShadow: '0px 3px 10px',
    borderRadius: '12px',
    maxHeight: height - 4, // alternative for marginBottom not working
  }

  return (
      <Card 
        trip={trip}
        cardStyle={cardStyle}
        dividerStyle={'8px -2px'}
        planUpdater={planUpdater && planUpdater(trip.planId)}
      />
  );
}