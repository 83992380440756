// TODO: currently showing on top of modal, needs to be lower layer

export const BlurBar = () => (
    <div style={
        {
            position: 'fixed',
            bottom: 0, //calc(1rem + env(safe-area-inset-bottom));
            left: '0px',
            width: '100%',
            height: '4.5vh',
            background: 'rgba(0,0,0,0.64)', //.05 for chrome
            WebkitBackdropFilter: 'blur(64px)', /* Add this line first, it fixes blur for Safari*/
            backdropFilter: 'blur(64px)', /* This line activates blur*/
        }
      }>
    </div>
    );

export default BlurBar;